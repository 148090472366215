import { Space } from "views/ai/space";
import { Create } from "views/ai/create/index";
import { CreateAssistant } from "views/ai/create/assistant";
import { CreateSpace } from "views/ai/create/space";
import { Spaces } from "views/app/spaces";

const Routes = [
  {
    path: "/ai/list/chatrooms",
    view: Spaces,
    layout: "app",
    permission: "user",
    title: "Chatrooms",
    data: { listType: "chatroom" },
  },

  {
    path: "/ai/list/topics",
    view: Spaces,
    layout: "app",
    permission: "user",
    title: "Topics",
    data: { listType: "workspace" },
  },
  {
    path: "/ai/list/topics/collection/:collection_id",
    view: Spaces,
    layout: "app",
    permission: "user",
    title: "Collection",
    data: { listType: "collection" },
  },

  {
    path: "/ai/create",
    view: Create,
    layout: "app",
    permission: "supertyro",
    title: "Create",
  },

  {
    path: "/ai/create/assistant",
    view: CreateAssistant,
    layout: "app",
    permission: "supertyro",
    title: "Create Assistant",
  },

  {
    path: "/ai/create/assistant/:assistant_id",
    view: CreateAssistant,
    layout: "app",
    permission: "supertyro",
    title: "Edit Assistant",
  },

  {
    path: "/ai/space/:space_id",
    view: Space,
    layout: "app",
    permission: "user",
    title: "Space",
  },

  {
    path: "/ai/space/:space_id/session/:session_id",
    view: Space,
    layout: "app",
    permission: "user",
    title: "Space",
  },

  {
    path: "/ai/space/:space_id/starter/:starter_id",
    view: Space,
    layout: "app",
    permission: "user",
    title: "Space",
  },

  {
    path: "/ai/create/space",
    view: CreateSpace,
    layout: "app",
    permission: "supertyro",
    title: "Create Space",
  },
  {
    path: "/ai/create/space/:space_id",
    view: CreateSpace,
    layout: "app",
    permission: "supertyro",
    title: "Edit Space",
  },
];

export default Routes;
