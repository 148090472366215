/***
 *
 *   HOME NAV
 *   Navigation used on the main external website. Renders a dashboard link
 *   if the user is signed in, or a sign up link if they are not
 *
 **********/

import { useContext } from "react";
import { AuthContext, Logo, Button, Link } from "components/lib";
import Style from "./auth.module.scss";

export function AuthNav(props) {
  // context
  const authContext = useContext(AuthContext);

  return (
    <nav className={Style.nav}>
      <Logo logo color />
    </nav>
  );
}
