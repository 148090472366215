/***
 *
 *   HOMEPAGE
 *   Homepage template with features, testimonials and CTA
 *
 **********/

import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  AuthContext,
  Card,
  Button,
  FeaturedItemCard,
  PricePlans,
  FullScreen,
  useOnInView,
  Icon,
} from "components/lib";

import Markdown from "react-markdown";

import compareData from ".//data/compare.data.json";
import featuredData from ".//data/featured.data.json";
import faqData from ".//data/faq.data.json";
import prayerData from ".//data/prayer.data.json";
import "./website.scss";

export function Home(props) {
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  //state
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [activeCompareIndex, setActiveCompareIndex] = useState(null); // Default to the first option
  const [activeChatIndex, setActiveChatIndex] = useState(4);
  const [activeTopicIndex, setActiveTopicIndex] = useState(1);
  const [activePrayerIndex, setActivePrayerIndex] = useState(0);
  const [infoOverlay, setInfoOverlay] = useState(false);
  const [infoData, setInfoData] = useState([]);
  const [isTyroVisible, setIsTyroVisible] = useState(true);

  // ref for the animate on view
  const [setElements, elements] = useOnInView();

  // get featured content
  const featuredTopic = featuredData.filter(
    (item) => item.type === "workspace"
  );
  const featuredChats = featuredData.filter((item) => item.type === "chatroom");

  useEffect(() => {
    setElements(document.querySelectorAll(".useOnInView"));
  }, []);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  }, [window.innerWidth]);

  useEffect(() => {
    elements.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("inView");
      }
    });
  }, [elements]);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  function showAi(data, type) {
    data.type = type;
    setInfoData(data);
    setInfoOverlay(true);
  }

  // Ref for the scrollable container
  const scrollContainerCompareRef = useRef(null);
  const scrollContainerTopicRef = useRef(null);
  const scrollContainerChatRef = useRef(null);
  const scrollContainerPrayerRef = useRef(null);

  const scrollTo = (ref, direction) => {
    if (ref.current) {
      const itemWidth = ref.current.children[0].offsetWidth;
      const currentScroll = ref.current.scrollLeft;
      let newScrollPosition;

      // Determine the new scroll position based on the direction
      if (direction === "forward") {
        newScrollPosition = currentScroll + itemWidth;
      } else if (direction === "backward") {
        newScrollPosition = currentScroll - itemWidth;
      } else {
        console.error("Invalid direction. Use 'forward' or 'backward'.");
        return; // Exit the function if the direction is invalid
      }

      ref.current.scrollTo({
        left: newScrollPosition,
        behavior: "auto", // Immediate snapping
      });
    }
  };

  useEffect(() => {
    scrollTo(scrollContainerTopicRef, "forward");

    scrollTo(scrollContainerCompareRef, "forward");

    scrollTo(scrollContainerPrayerRef, "forward");
  }, []); // Empty dependency array means this effect runs only once after the initial render

  // Toggle function that works only if isMobile is true
  const toggleVisibility = () => {
    if (isMobile) {
      setIsTyroVisible(!isTyroVisible);
    }
  };

  return (
    <div className="homeContent">
      <Card className="intro " transparent>
        <h1 className="gold">Catholic&nbsp;Ai</h1>
        <Button
          text="Try Now"
          icon="arrow-right-circle"
          color="brown"
          action={() => navigate("/pricing")}
        />
        <p>
          Answer life’s most pressing questions, learn more about your favorite
          topics and get help with everyday stuff. All from a Catholic world
          view.
        </p>
      </Card>

      <Card className="compare" center extraPadding>
        <div className="sectionHeading">
          <h1>Tyro Ai is trained on Catholic teaching</h1>
          <p>
            See the difference between Tyro and the leading Ai on moral issues
          </p>
        </div>

        <div className="compareOptions" ref={scrollContainerCompareRef}>
          {compareData.map((compare, index) => (
            <div
              key={index}
              className={`compareCard ${
                index === activeCompareIndex ? "active" : ""
              }`}
              onClick={() => setActiveCompareIndex(index)}
            >
              <h3>Q: {compare.title}</h3>
              <p>{compare.question}</p>
            </div>
          ))}
        </div>

        <div
          className={`comparisons ${
            activeCompareIndex === null || activeCompareIndex === undefined
              ? "closed"
              : ""
          }`}
        >
          {isMobile ? (
            isTyroVisible ? (
              <div className="left markdown">
                <div className="buttonContainer">
                  <button className="active">Tyro</button>
                  <button className="notActive" onClick={toggleVisibility}>
                    Leading Ai
                  </button>
                </div>
                <h3>Q: {compareData[activeCompareIndex]?.question}</h3>
                <hr />
                <Markdown>{compareData[activeCompareIndex]?.tyro}</Markdown>
              </div>
            ) : (
              <div className="right markdown">
                <div className="buttonContainer">
                  <button className="notActive" onClick={toggleVisibility}>
                    Tyro
                  </button>
                  <button className="active">Leading Ai</button>
                </div>

                <h3>Q: {compareData[activeCompareIndex]?.question}</h3>
                <hr />
                <Markdown>{compareData[activeCompareIndex]?.chat}</Markdown>
              </div>
            )
          ) : (
            <>
              <div className="left markdown">
                <cite>Tyro</cite>
                <h3>Q: {compareData[activeCompareIndex]?.question}</h3>
                <hr />
                <Markdown>{compareData[activeCompareIndex]?.tyro}</Markdown>
              </div>
              <div className="right markdown">
                <cite>Leading Ai</cite>
                <h3>Q: {compareData[activeCompareIndex]?.question}</h3>
                <hr />
                <Markdown>{compareData[activeCompareIndex]?.chat}</Markdown>
              </div>
            </>
          )}
        </div>
      </Card>

      <Card className="topics" transparent extraPadding>
        <div className="sectionHeading">
          <h1>Deepen your knowledge on important topics</h1>
          <p>
            Enhance your religious study using advanced technology, Ai puts even
            the most complex topics within reach.
          </p>
        </div>
        <div className="topicGraphics" ref={scrollContainerTopicRef}>
          {featuredTopic.map((topic, index) => {
            // Create a modified copy of topic based on isMobile condition
            const modifiedTopic = {
              ...topic,
              description: topic.question,
            };

            return (
              <FeaturedItemCard
                data={modifiedTopic} // Pass the modified topic here
                aiType="workspace"
                buttonText="Show Answer"
                key={index}
                onClick={() => {
                  showAi(featuredTopic[index], "workspace");
                  setActiveTopicIndex(index);
                }}
                className={`${
                  index === activeTopicIndex && !isMobile ? "selected" : ""
                } useOnInView`}
              ></FeaturedItemCard>
            );
          })}
        </div>
      </Card>

      <Card className="chat" extraPadding>
        <div className="sectionHeading">
          <h1>Historical figures from the Catholic Church</h1>
          <p>
            Enjoy life like conversations with Ai’s trained on the work and
            voice of prominent Catholic figures.
          </p>
        </div>

        {/* mobile version */}

        {isMobile && (
          <>
            <div className="chatContainerMobile" ref={scrollContainerChatRef}>
              {featuredChats.map((chat, index) => (
                <div className={"chatHighlight useOnInView"} key={index}>
                  <img
                    src={featuredChats[index].thumbnail_url}
                    alt={featuredChats[index].title}
                    className="chatImage"
                  />
                  <div className="chatQA">
                    <h2>{featuredChats[index].title}</h2>
                    <h3 className="question">
                      Q: {featuredChats[index].question}
                    </h3>

                    <Button
                      text="Show Answer"
                      icon="loader"
                      action={() => showAi(featuredChats[index], "chatroom")}
                      className="chatButton"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="scrollButtonContainer">
              <Button
                icon="arrow-left-circle"
                iconSize="44"
                action={() => scrollTo(scrollContainerChatRef, "backward")}
              />
              <Button
                icon="arrow-right-circle"
                iconSize="44"
                action={() => scrollTo(scrollContainerChatRef, "forward")}
              />
            </div>
          </>
        )}

        {/* desktop version */}
        {!isMobile && (
          <>
            <div className={"chatHighlight useOnInView"}>
              <img
                src={featuredChats[activeChatIndex].thumbnail_url}
                alt={featuredChats[activeChatIndex].title}
                className="chatImage"
              />
              <div className="chatQA">
                <h2>{featuredChats[activeChatIndex].title}</h2>
                <h3 className="question">
                  Q: {featuredChats[activeChatIndex].question}
                </h3>

                <Button
                  text="Show Answer"
                  icon="loader"
                  action={() =>
                    showAi(featuredChats[activeChatIndex], "chatroom")
                  }
                  className={activeChatIndex}
                />
              </div>
            </div>
            <div className="chatGraphics">
              <div className="grid-6">
                {featuredChats.map((chat, index) => (
                  <FeaturedItemCard
                    data={chat}
                    aiType="chatroom"
                    key={index}
                    onClick={() => setActiveChatIndex(index)}
                  ></FeaturedItemCard>
                ))}
              </div>
            </div>
          </>
        )}
      </Card>

      <Card className="prayer" transparent extraPadding>
        <div className="sectionHeading">
          <h1>Inspired Prayers</h1>
          <p>
            Learn from the great prayers of history or create personalized
            prayers for anything on your heart.
          </p>
        </div>
        <div className="prayerOptions" ref={scrollContainerPrayerRef}>
          {prayerData.map((prayer, index) => (
            <div
              key={index}
              className={`prayerCard useOnInView ${
                index === activePrayerIndex && !isMobile ? "active" : ""
              }`}
              onClick={() => {
                if (isMobile) {
                  showAi(prayerData[index]);
                } else {
                  setActivePrayerIndex(index);
                }
              }}
            >
              <h3>{prayer.title}</h3>
              {prayer.question}
            </div>
          ))}
        </div>
        {!isMobile && (
          <div className="prayerQA">
            <h3>{prayerData[activePrayerIndex].title}</h3>

            <p>Q: {prayerData[activePrayerIndex].question}</p>
            <Button
              text="Show Answer"
              icon="loader"
              action={() => showAi(prayerData[activePrayerIndex], "prayer")}
              className={activePrayerIndex}
            />
          </div>
        )}
      </Card>

      <PricePlans />

      <Card className="faq" transparent extraPadding>
        <div className="sectionHeading">
          <h1>Questions and Answers</h1>
        </div>
        <div className="faqContainer">
          {faqData.map((item, index) => (
            <div key={index} className="faq-item">
              <div
                className={`faq-question ${
                  expandedIndex === index ? "open" : ""
                }`}
                onClick={() => toggleExpand(index)}
              >
                <p>{item.question}</p>
              </div>
              {expandedIndex === index && (
                <p className="faq-answer">{item.answer}</p>
              )}
            </div>
          ))}
        </div>
      </Card>
      {infoOverlay && (
        <FullScreen
          large
          className="infoOverlay"
          closeFunction={() => setInfoOverlay(false)}
          closeBtn
        >
          <div className="top fullScreenHeader">
            {infoData.thumbnail_url && (
              <img
                src={infoData.thumbnail_url}
                alt={infoData.title}
                className={infoData.type}
              />
            )}
            <div>
              <h1>
                <span className="capitalize"> {infoData.title}</span>
              </h1>
            </div>
          </div>
          <div className="fullScreenContent">
            <div className="markdown">
              <p className="question">{`Q: ${infoData.question}`}</p>
              <Markdown>{infoData.answer}</Markdown>
            </div>
          </div>
          <cite>Responses are generated by Ai and may contain errors.</cite>
        </FullScreen>
      )}
    </div>
  );
}
