/***
 *
 *   PRICE PLANS
 *   Pricing table for your landing/pricing page
 *   Update the features array in /config file with your own
 * plan.name
 * plan.price
 * plan.interval
 * plan.description
 *
 *
 **********/

import { Card, Icon, Button, useNavigate, usePlans } from "components/lib";
import Style from "./pricing.module.scss";

export function PricePlans(props) {
  const navigate = useNavigate();
  const plans = usePlans();

  if (!plans.data) return false;

  return (
    <Card className={Style.plans} extraPadding>
      <div className="sectionHeading">
        <h1>Pricing</h1>
        <p>
          Start with a <strong>FREE 7 day trial</strong> then pay what you can
          afford. Pay a little more to help someone else pay less.
        </p>
      </div>

      <div className={Style.pricingOptions}>
        {plans?.data?.raw?.plans.map((plan, index) => {
          return (
            <div className={Style.priceCard} key={index}>
              {index === 3 && (
                <div className={Style.charm}>
                  <Icon image="heart" size="44" />
                </div>
              )}
              <small>Free 7 day trial then</small>
              <h3>
                {plan?.currency?.symbol}
                {plan.price}
                {plan.interval && "/"}
                {plan.interval === "monthly" ? "month" : plan.interval}{" "}
              </h3>
              <hr />
              <p>{plan.description}</p>
              <Button
                text="Get Started"
                icon="arrow-right-circle"
                color="green"
                action={(e) => {
                  localStorage.setItem("selectedPlanId", plan.id);
                  navigate(`/signup/plan#${plan.id}`);
                }}
              />
            </div>
          );
        })}
      </div>
    </Card>
  );
}
