import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  FeaturedItemCard,
  Animate,
  Feedback,
  useAPI,
  DiscoverContainer,
  CollectionCard,
  PrayerButton,
  Icon,
  Footer,
  Button,
  Link,
} from "components/lib";
import Style from "./discover.view.scss";

export function Discover() {
  // used to update the URL
  const navigate = useNavigate();

  //get the list of collections
  const collections = useAPI("/api/ai/collections");

  //get the list of recnet sessions
  const sessions = useAPI("/api/ai/sessions/recent");

  //get the featured starters
  const featuredStarters = useAPI("/api/ai/getFeaturedStarters") || [];

  const [starters, setStarters] = useState([]);
  const [starterSpaceId, setStarterSpaceId] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (!featuredStarters?.loading && featuredStarters?.data) {
        setStarters(featuredStarters.data);
        setStarterSpaceId(featuredStarters.data.space_id);
      }
    }
    fetchData();
  }, [featuredStarters]);

  // get featured content
  const featured = useAPI(`/api/ai/discover/featured`).data || [];
  const featuredSpaces = featured.filter((item) => item.type === "workspace");
  const featuredChats = featured.filter((item) => item.type === "chatroom");

  const handleSubmit = (event) => {
    event.preventDefault();

    let question;
    if (event.type === "submit") {
      question = event.target.elements.question.value;
    } else if (event.type === "keydown" && event.key === "Enter") {
      question = event.target.value;
    }
    navigate("/ai/space/71dbc140-7180-495a-a5bd-a5f177657a99", {
      state: { question },
    });
  };

  // helper function to submit the form when the user presses enter and new line with shift+enter
  function enterSubmit(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      handleSubmit(event);
    }
  }

  return (
    <div className="discover">
      <Animate type="slidedown" timeout={100}>
        <header className="discover-header">
          <div className="section1">
            <h1>Discover</h1>
            <h3>
              Meet Tyro, a distinctly Catholic Ai. Ask anything to get started
            </h3>
            <form
              id="questionForm"
              method="post"
              onSubmit={handleSubmit}
              onKeyDown={enterSubmit}
            >
              <div className="questionWrapper">
                <textarea
                  id="question"
                  type="text"
                  name="question"
                  required
                ></textarea>
                <button type="submit">
                  <Icon image="arrow-up" />
                </button>
              </div>
            </form>
          </div>

          <div className="section2">
            <h2>Recent Conversations</h2>
            <div className="progressBar"></div>
            {sessions && sessions.data?.length > 0
              ? sessions.data.map((session) => (
                  <div key={session.id} className="session">
                    <Link
                      text={session.title}
                      url={`/ai/space/${session.parent_id}/session/${session.id}`}
                    />
                  </div>
                ))
              : "Looks like you haven't started any conversations yet. Just type your question in the area to the left to get started."}
          </div>
        </header>
      </Animate>
      <Animate timeout={100}>
        <DiscoverContainer
          title="Featured Topics"
          subtitle="Religious topics with deep insight and wisdom"
          goto="../ai/list/topics"
          transparent
        >
          <div className="grid-4">
            {featuredSpaces?.map((space) => {
              return (
                <FeaturedItemCard
                  data={space}
                  className={space.active ? "active" : "notActive"}
                  aiType="workspace"
                  goto={`/ai/space/${space.id}`}
                  key={space.id}
                ></FeaturedItemCard>
              );
            })}
          </div>
        </DiscoverContainer>

        <DiscoverContainer
          title="Featured Figures"
          subtitle="Historical figures from the Catholic Church"
          goto="../ai/list/chatrooms"
        >
          <div className="grid-6">
            {featuredChats?.map((space) => {
              return (
                <FeaturedItemCard
                  data={space}
                  className={space.active ? "active" : "notActive"}
                  square={false}
                  aiType="chatroom"
                  goto={`/ai/space/${space.id}`}
                  key={space.id}
                ></FeaturedItemCard>
              );
            })}
          </div>
        </DiscoverContainer>

        <DiscoverContainer
          title="Collections"
          subtitle="Collections of spaces based on topic"
          className="collection"
          transparent
        >
          <div className="grid-4">
            {collections?.data?.map((collection, index) => {
              return (
                <CollectionCard
                  key={collection.id}
                  id={collection.id}
                  title={collection.title}
                  description={collection.description}
                  spaces={collection.spaces}
                ></CollectionCard>
              );
            })}
          </div>
        </DiscoverContainer>

        <DiscoverContainer
          title="Prayer and reflections"
          subtitle="Reflect in prayer with these starter ideas"
        >
          <div className="grid-5">
            {starters.data?.map((starter, index) => (
              <PrayerButton
                key={index}
                title={starter.title}
                description={starter.description}
                starter_id={starter.id}
                space_id={starterSpaceId}
              />
            ))}
          </div>
        </DiscoverContainer>

        <DiscoverContainer
          className="featured discover-donate"
          transparent
          bgimage
          dark
          center
        >
          <div className="support">
            <h1>
              Support building Ai technology <br />
              to grow the Catholic Faith
            </h1>
            <br />
            <Button text="Donate" goto="/donate" />
          </div>
        </DiscoverContainer>

        <Footer />
      </Animate>
      <div id="snackbar"> </div>

      <Feedback />
    </div>
  );
}
