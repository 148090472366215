/***
 *
 *   APP NAV
 *   Primary navigation used inside the main app component
 *
 *   PROPS
 *   type: fixed or popup (style of nav on mobile)
 *   items: array of objects containing label, link and icon (optional)
 *   usertype: user type (master, user, etc)
 *
 **********/

import { useState, useContext, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import {
  LogoSVG,
  Button,
  Icon,
  AuthContext,
  ViewContext,
} from "components/lib";
import "./app.scss";

export function AppNav(props) {
  const authContext = useContext(AuthContext);
  const viewContect = useContext(ViewContext);

  // state
  const [menuOpen, setOpenMenu] = useState(false); // mobile is open
  const [expanded, setExpanded] = useState(false); // desktop is expanded

  // kinda like getElementById
  const navbar = useRef(null);

  // uses custom hook to track scroll direction and changes the nav bar accordingly
  useEffect(() => {
    if (viewContect.scrollDirection === "down") {
      console.log("app", viewContect.scrollDirection);

      navbar.current.style.top = `-50px`;
    } else {
      navbar.current.style.top = `0px`;
      console.log("app", viewContect.scrollDirection);
    }
  }, [viewContect.scrollDirection]);

  // when user click a link, close the menu
  const closeMenu = function () {
    setOpenMenu(!menuOpen);
  };

  return (
    <nav
      className={`sidebar popup ${menuOpen && "open"} ${
        expanded && "expanded"
      }`}
      onMouseEnter={(e) => setExpanded(true)}
      onMouseLeave={(e) => setExpanded(false)}
    >
      <div className={`nav-bar`} ref={navbar}>
        <Button
          icon={menuOpen ? "x" : "menu"}
          size={25}
          className="btn-togglenav"
          action={(e) => setOpenMenu(!menuOpen)}
        />
        <LogoSVG className="logoLink" link="/" />
      </div>

      <LogoSVG className="logoLink " link="/" />
      <section className="nav-links">
        {props.items?.map((item) => {
          if (item.link) {
            if (item.permission && !authContext.permission[item.permission])
              return false;
            return (
              <NavLink onClick={closeMenu} key={item.label} to={item.link}>
                {item.icon && (
                  <Icon className="icon" image={item.icon} size={18} />
                )}
                {item.label && <span className="label">{item.label}</span>}
              </NavLink>
            );
          }

          return (
            <div key={item.label} onClick={item.action}>
              {item.icon && (
                <Icon className="icon" image={item.icon} size={18} />
              )}
              {item.label && <span className="label">{item.label}</span>}
            </div>
          );
        })}
      </section>
    </nav>
  );
}
