/***
 *
 *   HOME NAV
 *   Navigation used on the main external website. Renders a dashboard link
 *   if the user is signed in, or a sign up link if they are not
 *
 **********/

import { useContext } from "react";
import { AuthContext, Logo, Link, Button } from "components/lib";

export function HomeNav(props) {
  // context
  const context = useContext(AuthContext);

  return (
    <header className="homeHeader">
      <Logo logo color />
      <div className="signin">
        {context.user?.token ? (
          <>
            <Button small goto="/discover" text="Enter" />
          </>
        ) : (
          <>
            <Link url="/signin" text="Sign In" color="white" />

            <Button
              small
              goto="/signup"
              text="Sign Up"
              className="inline-block"
            />
          </>
        )}
      </div>
    </header>
  );
}
